/* eslint-disable arrow-parens */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import objects3DRender from '@/src/3DObjects';
import LayerSwitcher from '@/src/components/LayerSwitcher';
import { MapCtx } from '@/src/contexts';
import { addQueryStringToUrl } from '@/src/helpers';

const ButtonStyle = css`
  flex: 1;
  outline: none;
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #fff;
  color: rgba(0, 0, 0, 0.54);
  font-family: 'SF Pro Text';
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 19px;
  text-align: center;
  &:active {
    background-color: #dedede;
  }
`;

const BottomMobileMenu = styled.div`
  width: 100%;
  height: 62px;
  flex-direction: row;
  display: none;
  transition: height 0.2s ease-in-out;
  &.is-opened {
    display: flex;
  }
  
`;

const LangMenu = styled.div`
  position: absolute;
  bottom: 61px;
  width: 100%;
  left: 0;
  display: none;
  &.is-opened {
    display: block;
  }
`;

const Lang = styled.span`
  outline: none;
  text-decoration: none;
  display: block;
  background-color: #f7f7f7;
  padding: 11px 0;
  color: rgba(0, 0, 0, 0.54);
  font-family: 'SF Pro Text';
  font-size: 1.6rem;
  width: 100%;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 19px;
  text-align: center;
  &.is-active {
    background-color: #dedede;
  }
  &:active {
    background-color: #dedede;
  }
`;

const MenuButton = styled.button`
  ${ButtonStyle}
  &.is-lang {
    position: relative;
    -webkit-tap-highlight-color: transparent;
  }
`;

const MenuSpan = styled.span`
  ${ButtonStyle}
`;

const MobileMenu = (props) => {
  const {
    i18n, t, router, isOpened 
  } = props;
  const { viewType, year } = router.query;
  const { MapGL, objects3D } = useContext(MapCtx);
  const [isOpenedLangs, setOpenLanguages] = useState(false);
  const [pitchValue, changePitch] = useState(MapGL.getPitch());
  const currentLang = i18n.language;
  const alterMode = !pitchValue ? '3D' : '2D';

  const onClick = y => () => {
    addQueryStringToUrl({ year: y }, false, true);
  };

  const getSuffix = (yr) => {
    if (SUFFIX_YEARS.indexOf(yr) > -1) {
      return `${yr}-e`;
    }
    return yr;
  };


  const toggle3DRender = (pitch) => {
    if (pitch > 0 && viewType !== 'sateliteStyle') {
      // objects3DRender(MapGL, objects3D, i18n.language);
      objects3D.forEach((object) => {
        if (MapGL.getLayer(object.title)) {
          MapGL.setLayoutProperty(object.title, 'visibility', 'visible');
        }
      });
    }
  };

  const toggleLanguages = () => {
    setOpenLanguages(!isOpenedLangs);
  };

  const setLang = (lang) => (e) => {
    if (currentLang === lang) return;
    e.preventDefault();
    window.TOPBAR.setLang(lang);
  };

  const setPitch = () => {
    if (pitchValue > 0) {
      MapGL.flyTo({ pitch: 0 });
      changePitch(0);
      return;
    }
    MapGL.flyTo({ pitch: 60 });
    changePitch(60);
  };

  useEffect(() => {
    MapGL.once('pitchend', (e) => {
      const pitch = e.target.getPitch();
      toggle3DRender(pitch);
      changePitch(pitch);
    });
  }, [viewType]);

  // const date = new Date();
  // const currentYear = date.getFullYear();

  return (
    <BottomMobileMenu className={isOpened && 'is-opened'}>
      <MenuButton onClick={setPitch}>{t(alterMode)}</MenuButton>
      {year !== '1880' && (
        <MenuSpan>
          <LayerSwitcher MapGL={MapGL} onlyText />
        </MenuSpan>
      )}
      <MenuButton className='is-lang' onClick={toggleLanguages}>
        <LangMenu className={isOpenedLangs ? 'is-opened' : ''}>
          <Lang 
            onClick={onClick('1880')}
          >
            1880-е
          </Lang>
          <Lang 
            onClick={onClick('1940')}
          >
            1940-е
          </Lang>
          <Lang 
            onClick={onClick('1960')}
          >
            1960-е
          </Lang>
          <Lang 
            onClick={onClick('2020')}
          >
            2020-е
          </Lang>
          {/* <Lang 
            href='/ro' 
            className={currentLang === 'ro' ? 'is-active' : ''} 
            onClick={setLang('ro')}
          >
            RO
          </Lang> */}
          {/* <Lang 
            href='/ru' 
            className={currentLang === 'ru' ? 'is-active' : ''} 
            onClick={setLang('ru')}
          >
            RU
          </Lang>
          <Lang 
            href='/en' 
            className={currentLang === 'en' ? 'is-active' : ''} 
            onClick={setLang('en')}
          >
            EN
          </Lang> */}
        </LangMenu>
        {year || '1940'}
        -e
      </MenuButton>
    </BottomMobileMenu>
  );
};

MobileMenu.defaultProps = {
  isOpened: false,
};
MobileMenu.propTypes = {
  t: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  isOpened: PropTypes.bool,
};

export default withTranslation()(withRouter(MobileMenu));
