import React from 'react';

export const MapCtx = React.createContext({
  MapGL: null,
});

export const MapToolsCtx = React.createContext({
  mapTools: {},
});

export const TogglerAddPlaceCtx = React.createContext({
  togglerAddPlace: null,
});
