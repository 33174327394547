import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { addQueryStringToUrl } from '@/src/helpers';
import { MapCtx } from '@/src/contexts';
import objects3DRender from '@/src/3DObjects';
import routingStyles from '@/public/static/routingStyles';
import { setMapViewType } from '@/src/redux/actions';

const SimpleSwitch = styled.button`
  color: rgba(0, 0, 0, 0.54);
  font-family: 'SF Pro Text';
  font-size: 1.6rem;
`;

const Wrap = styled.div`
  position: absolute;
  right: 15px;
  bottom: 13px;
  z-index: 100;
  &:not(.is-embed) {
    ${({ theme }) => theme.onSM(`
      display: none;
    `)}
  }
  @media screen and (max-width: 300px) {
    right: unset;
    left: 3px;
  }
`;

const Switcher = styled.a`
  cursor: pointer;
  display: inline-block;
  width: 56px;
  height: 56px;
  margin-left: 10px;
  border: 3px solid #fff;
  border-radius: 50%;
  background-image: url('/static/images/${({ image }) => image}');
  background-position: ${({ layer }) => (layer === 'sateliteStyle' ? '0px' : '-49px')} 0px;
`;

const LayerSwitcher = ({
  MapGL, router, mapStyle, sateliteStyle, onlyText, setMapViewTypeHandler, t
}) => {
  const {
    viewType, embed, print, routingMode, year
  } = router.query;

  const [layer, setLayer] = useState(viewType || 'mapStyle');
  const { objects3D } = useContext(MapCtx);

  const mapGetData = (initialStyles) => {
    // const { directions, points } = routingStyles;
    const mapStyles = JSON.parse(JSON.stringify(initialStyles));
    const initialSource = MapGL.getStyle().sources;
    const mapLine = [];
    MapGL.getStyle()
      .layers.slice(0)
      .reduce((acc, mapLayer, index, initialArray) => {
        if (!mapLayer.id.match(/(vector-|raster-|directions-|mapLine|busStops0|publicRoute0|publicRouteTo|publicRouteFrom)/)) {
          acc.push(mapLayer);
        }
        if (mapLayer.id.match(/(mapLine|busStops0|publicRoute0|publicRouteTo|publicRouteFrom)/)) {
          mapLine.push(mapLayer);
        }
        if ((initialArray.length - 1) === index) {
          mapStyles.layers.push(...acc.reverse());
        }
        return acc;
      }, []);
    Object.keys(initialSource).map((source) => {
      if (initialSource[source].type !== 'vector' && initialSource[source].type !== 'raster') {
        mapStyles.sources[source] = initialSource[source];
      }
    });
    MapGL.loadImage('/static/images/routing-point.png', (_, image) => {
      if (!MapGL.hasImage('pointRoutings')) {
        MapGL.addImage('pointRoutings', image);
      }
    });
    // Replace directionsLayers, PublicLayers, mapLine before "highway-name-path" for correct mapLayers order display
    // Need refactor
    const newDirections = []
    // const newDirections = directions.map((directionLayer) => {
    //   const resp = directionLayer;
    //   if (resp.layout) {
    //     if (routingMode === 'public') {
    //       resp.layout.visibility = 'none';
    //     } else {
    //       resp.layout.visibility = 'visible';
    //     }
    //   }
    //   return resp;
    // });
    for (let a = 0; a <= mapStyles.layers.length; a += 1) {
      if (mapStyles.layers[a].id.match(/highway-name-path/)) {
        mapStyles.layers.splice(a, 0, ...newDirections, ...mapLine);
        // mapStyles.layers.push(...points);
        break;
      }
    }
    return mapStyles;
  };

  const changeLayer = (prevLayer, nextLayer) => {
    if ((prevLayer === nextLayer) || !MapGL.isStyleLoaded()) return;
    // addQueryStringToUrl({ viewType: nextLayer }, false, true);
    addQueryStringToUrl({ viewType: nextLayer }, true);
    let mapData;
    if (nextLayer === 'sateliteStyle') {
      // if (!MapGL.hasImage('unclusterPoint') || !MapGL.hasImage('pointRoutings')) {
      //   MapGL.loadImage('/static/images/routing-point.png', (_, rp) => {
      //     MapGL.loadImage('/static/images/unclusteredMarker.png', (u, um) => {
      //       mapData = mapGetData(sateliteStyle);
      //       MapGL.setStyle(mapData, { diff: false });
      //       if (!MapGL.hasImage('unclusterPoint')) {
      //         MapGL.addImage('unclusterPoint', um);
      //       }
      //       if (!MapGL.hasImage('pointRoutings')) {
      //         MapGL.addImage('pointRoutings', rp);
      //       }
      //     });
      //   });
      // } else {
      mapData = mapGetData(sateliteStyle);
      MapGL.setStyle(mapData, { diff: false });
      // }
    } else {
      mapData = mapGetData(mapStyle);
      MapGL.setStyle(mapData, { diff: false });
      // MapGL.once('styledata', () => {
      //   if (MapGL.getPitch() <= 0 || nextLayer === 'sateliteStyle') return;
      //   objects3DRender(MapGL, objects3D);
      // });
    }
    setMapViewTypeHandler(nextLayer);
    setLayer(nextLayer);
  };

  const alterStyle = layer === 'sateliteStyle' ? 'mapStyle' : 'sateliteStyle';
  if (year === '1880') {
    changeLayer('sateliteStyle', 'mapStyle')
    return null;
  }
  if (onlyText) {
    return <SimpleSwitch onClick={() => changeLayer(layer, alterStyle)}>{t(alterStyle)}</SimpleSwitch>;
  }

  return (
    <Wrap className={(embed || print) && 'is-embed'}>
      <Switcher image={(!year || (year !== '2020')) ? `view-type-icons-${year || '1940'}.png` : 'view-type-icons.png'} onClick={() => changeLayer(layer, alterStyle)} layer={layer} />
    </Wrap>
  );
};

LayerSwitcher.defaultProps = {
  onlyText: false,
};

LayerSwitcher.propTypes = {
  t: PropTypes.func.isRequired,
  MapGL: PropTypes.object.isRequired,
  mapStyle: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  onlyText: PropTypes.bool,
  sateliteStyle: PropTypes.object.isRequired,
  setMapViewTypeHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  mapStyle: state.tileStyles.mapStyles,
  sateliteStyle: state.tileStyles.sateliteStyles,
});

const mapDispatchToProps = dispatch => ({
  setMapViewTypeHandler: viewType => dispatch(setMapViewType(viewType))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(LayerSwitcher)));
