import React, { useState } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import classNames from 'classnames';
import { toggleMapShow } from '@/src/redux/actions';
import { replaceURLPage, changeURLPage } from '@/src/helpers';
import MobileMenu from './MobileMenu';

const Button = styled.button`
  padding: 0 12px;
  cursor: pointer;
  &:last-child {
    padding-right: 0;
  }
  ${({ theme }) => theme.fromSM(`
    &:hover {
      & * {
        color: ${theme.colors.accent};
      }
    }
  `)}
`;

const Wrapper = styled.div`
  flex-wrap: wrap;
  transition: bottom 0.2s ease-in-out;
  &.is-bottom {
    display: none;
    position: relative;
    flex: 0 1 auto;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -4px 9px -3px rgba(0, 0, 0, 0.14);
    z-index: 999;
    & > ${Button} {
      flex: 1 0 auto;
      padding: 10px 12px 10px !important;
      border-bottom: 1px solid #ededed;
    }
  }
  ${({ theme }) => theme.onSM(`
    &.is-bottom {
      display: flex;
    }
  `)}
`;

const SidebarToggle = styled.button`
  position: absolute;
  z-index: 999;
  top: -56px;
  height: 36px;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
  width: 106px;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  &:hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.onSM(`
      display: block;
  `)}
`;

const Icon = styled.span`
  font-size: 2.4rem;
  color: rgba(0, 0, 0, 0.54);
  width: 24px;
  display: inline-block;
  &:before {
    margin: 0;
  }
  &.active {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const ViewSwitcher = (props) => {
  const {
    isBottom, pageName, t, actionToggleMapShow, mapShow, router
  } = props;
  const {
    query: { q, pageType },
  } = router;
  const [isBottomMenuOpened, openBottomMenu] = useState(false);

  const handleViewChange = (view) => {
    if (pageName === view) {
      replaceURLPage({ page: '', clearSearch: !q });
      return;
    }
    actionToggleMapShow(false);
    if (view === 'routing') {
      changeURLPage(view);
    } else {
      replaceURLPage({ page: view, clearSearch: !q });
    }
  };

  const toggleSidebarViews = () => {
    actionToggleMapShow(!mapShow);
  };

  const toggleBottomMenu = () => {
    openBottomMenu(!isBottomMenuOpened);
  };

  return (
    <Wrapper 
      className={classNames({
        'is-bottom': isBottom,
        'is-opened': isBottomMenuOpened,
      })
      }
    >
      {isBottom && (<MobileMenu isOpened={isBottomMenuOpened} />)}
      {/* To do - refactor SidebarToggle render condition task #993 */}
      {isBottom && ((!!pageName && pageName !== 'routes') || !!q || !!pageType) && (
        <SidebarToggle
          type='button'
          onClick={toggleSidebarViews}
        >
          {mapShow ? t('To list') : t('On map')}
        </SidebarToggle>
      )}
      { isBottom && (pageName === 'routes' && mapShow) && (
        <SidebarToggle
          type='button'
          onClick={toggleSidebarViews}
        >
          {mapShow ? t('To list') : t('On map')}
        </SidebarToggle>
      )}
      {/* <Button
        data-rh-at='bottom'
        data-rh={!isBottom ? t('Routes') : null}
        onClick={() => handleViewChange('routes')}
      >
        <Icon className={`icon-directions_bus ${pageName === 'routes' && 'active'}`} />
      </Button>
      <Button
        data-rh-at='bottom'
        data-rh={!isBottom ? t('Routing') : null}
        onClick={() => handleViewChange('routing')}
      >
        <Icon className={`icon-directions ${pageName === 'routing' && 'active'}`} />
      </Button> */}
      {isBottom && (
        <Button onClick={toggleBottomMenu}>
          <Icon className={`icon-${isBottomMenuOpened ? 'expand_more' : 'burger'}`} />
        </Button>
      )}
      
    </Wrapper>
  );
};

ViewSwitcher.defaultProps = {
  isBottom: false,
  mapShow: false,
  pageName: ''
};

ViewSwitcher.propTypes = {
  t: PropTypes.func.isRequired,
  isBottom: PropTypes.bool,
  actionToggleMapShow: PropTypes.func.isRequired,
  pageName: PropTypes.string,
  mapShow: PropTypes.bool,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  mapShow: state.map.mapShow,
  activeView: state.views.active,
});

const mapDispatchToProps = dispatch => ({
  actionToggleMapShow: show => dispatch(toggleMapShow(show)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ViewSwitcher))
);
