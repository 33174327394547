/* eslint-disable max-len */
/* eslint-disable no-console */
import * as THREE from 'three';
import Threebox from 'threebox-plugin';
import GLTFLoader from 'three-gltf-loader';
import * as CONST from '@/constants';
import { hostResolver, addQueryStringToUrl } from '@/src/helpers';
// import { RGBELoader } from '../../public/static/vendor/RGBELoader';
// import routingStyles from '@/routingStyles';

const fromLL = (lon, lat) => {
  // derived from https://gist.github.com/springmeyer/871897
  const extent = 20037508.34;

  const x = (lon * extent) / 180;
  let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * extent) / 180;

  return [(x + extent) / (2 * extent), 1 - (y + extent) / (2 * extent)];
};

const addObjectsToMap = (map, objects, lang) => { // here was cb() as func argument
  let glCtx = null;
  objects.map((object) => {
    const {
      transform, renderingMode, name, path, lng, lat, id
    } = object;
    
    if (map.getLayer(name)) return;
    const objectToAdd = {
      id: name,
      type: 'custom',
      renderingMode,
      onAdd(_map, gl) {
        if (!glCtx) {
          glCtx = gl;
        }        
        window.tb = new Threebox.Threebox(
          _map,
          _map.getCanvas().getContext('webgl'),
          { 
            defaultLights: true,
            enableSelectingFeatures: true,
            enableSelectingObjects: true,
            enableToltips: true,
            defaultCursor: 'pointer',
          }
        );
        const options = {
          obj: path,
          type: 'gltf',
          // loader,
          scale: transform.scale,
          units: 'meters',
          rotation: { x: 90, y: transform.rotateY, z: 0 },
          anchor: 'center',
          // adjustment: { x: 0.35, y: 0.80, z: 0 },
        };

        // window.tb.defaultCursor = 'pointer';
        window.tb.loadObj(options, (model) => {
          const obj = model.setCoords([lng, lat]);
          // obj.color = 0xa7a7a7;

          // const { x, y, z } = obj.anchor;
          // if (info.title && info.title[lang]) {
          //   // obj.addTooltip(`<div class='mapboxgl-popup object3D mapboxgl-popup-anchor-bottom'><div class='mapboxgl-popup-content'>${info.title[lang]}</div></div>`, true, { x: x + 30, y: y + 400, z }, false, 1);
          //   obj.addLabel(`<div class='mapboxgl-popup object3D mapboxgl-popup-anchor-bottom'><div class='mapboxgl-popup-content'>${info.title[lang]}</div></div>`, false, { x, y, z }, 0.5);
          // }
          obj.addEventListener('SelectedChange', (e) => {
            if (e.detail.selected) {
              addQueryStringToUrl({ object: id }, true, false);
            }
          }, false);
          window.tb.add(obj);
        });
      },
      render(gl, matrix) {
        window.tb.update();
      },
    };
    
    map.addLayer(objectToAdd);
  });
  // cb();
};

const addOldObjectsToMap = (map, objects) => { // here was cb() as func argument
  THREE.Cache.enabled = true;
  let glCtx = null;
  const loader = new GLTFLoader();
  objects.map((object) => {
    const {
      transform, renderingMode, name, path, ambientLight, withDirectionalLight 
    } = object;
    
    if (map.getLayer(name)) return;
    const camera = new THREE.Camera();
    const scene = new THREE.Scene();

    const objectToAdd = {
      id: name,
      type: 'custom',
      renderingMode,
      onAdd(_, gl) {
        if (!glCtx) {
          glCtx = gl;
        }

        const dL = new THREE.AmbientLight(0xffffff, ambientLight);
        scene.add(dL);
        if (withDirectionalLight) {
          const dL1 = new THREE.DirectionalLight(0xffffff, 0.1);
          dL1.position.set(1, 0, 0).normalize();
          scene.add(dL1);
        }
        const dL2 = new THREE.DirectionalLight(0xffffff, 0.2);
        dL2.position.set(0, 1, 0).normalize();
        scene.add(dL2);

        const dL4 = new THREE.DirectionalLight(0xffffff, 0.1);
        dL4.position.set(-1, 1, 0).normalize();
        scene.add(dL4);
        loader.load(path, (gltf) => {
          scene.add(gltf.scene);
        });

        const oldLogFunction = console.log;
        console.log = () => {};
        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: glCtx,
          antialias: false,
        });
        console.log = oldLogFunction;

        // this.renderer.gammaOutput = false;

        this.renderer.autoClear = false;
      },
      render(gl, matrix) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), transform.rotateX);
        const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), transform.rotateY);
        const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), transform.rotateZ);

        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
          .makeTranslation(transform.translateX, transform.translateY, transform.translateZ)
          .scale(new THREE.Vector3(transform.scale, -transform.scale, transform.scale))
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        camera.projectionMatrix.elements = matrix;
        camera.projectionMatrix = m.multiply(l);
        this.renderer.state.reset();
        this.renderer.render(scene, camera);
        map.triggerRepaint();
      },
    };
    map.addLayer(objectToAdd);
  });
  // cb();
};

const objects3DRender = (map, objects3D, lang, pitch) => {
  // const hasDirections = 0;
  if (!objects3D || !objects3D.length) return;
  const object3DToAdd = objects3D.reduce((acc, item) => {
    const {
      title,
      lat,
      lng,
      info,
      year,
      id,
      gltf_file_id,
      viewport
    } = item;
    const {
      ambient_light,
      rendering_mode,
      transform
    } = viewport;
    const {
      translate_z,
      rotate_x,
      rotate_y,
      rotate_z,
      scale 
    } = transform;

    if (!gltf_file_id) return acc;
    if (!lat || !lng) return acc;
    if (!translate_z || !rotate_y || !scale) return acc;

    acc.push({
      id,
      name: title,
      year,
      path: `${hostResolver(CONST.GET_3D_OBJECT_FILE)}${gltf_file_id}`,
      ambientLight: ambient_light || 0.5,
      renderingMode: rendering_mode || '3d',
      info,
      lng, 
      lat,
      transform: {
        translateX: fromLL(lng, lat)[0],
        translateY: fromLL(lng, lat)[1],
        translateZ: translate_z,
        rotateX: rotate_x || Math.PI / 2,
        rotateY: rotate_y,
        rotateZ: rotate_z || 0,
        scale,
      }
    });
    return acc;
  }, []);
  setTimeout(() => {
    if (object3DToAdd && object3DToAdd[0] && object3DToAdd[0].year === 0) {
      addOldObjectsToMap(map, object3DToAdd, lang);
      return;
    }
    addObjectsToMap(map, object3DToAdd, lang);
  }, 300);
  
  setTimeout(() => {
    if (pitch === 0 && objects3D && objects3D.length) {
      objects3D.forEach((object) => {
        if (map.getLayer(object.title)) {
          map.setLayoutProperty(object.title, 'visibility', 'none');
        }
      });
    }
  }, 600);
  // () => {
  // if (map.getSource('directions')) {
  //   map.getStyle().layers.map((layer) => {
  //     if (layer.id.indexOf('directions-') > -1) {
  //       map.removeLayer(layer.id);
  //       map.addLayer(layer, 'vector-highway-name-path');
  //       hasDirections += 1;
  //     }
  //     return layer;
  //   });
  //   if (!hasDirections) {
  //     routingStyles.map((directionLayer) => {
  //       map.addLayer(directionLayer, 'vector-highway-name-path');
  //       return directionLayer;
  //     });
  //   }
  // }
  // return Promise.resolve(object3D);
  // addObjectsToMap(map, object3DToAdd, () => {
  //   resolve();
  // });
// });
};

export default objects3DRender;
