const styles = {
  directions: [
    {
      id: 'directions-route-line-alt',
      type: 'line',
      source: 'directions',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#E1E1E1',
        'line-width': 7,
      },
      filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'alternate']],
    },
    {
      id: 'directions-route-line-alt-casing',
      type: 'line',
      source: 'directions',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#9E9E9E',
        'line-width': 5,
      },
      filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'alternate']],
    },
    {
      id: 'directions-route-line-casing',
      type: 'line',
      source: 'directions',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#2d5f99',
        'line-width': 0,
      },
      filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'selected']],
    },
    {
      id: 'directions-route-line',
      type: 'line',
      source: 'directions',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          property: 'congestion',
          type: 'categorical',
          default: '#ff7201',
          stops: [
            ['unknown', '#4882c5'],
            ['low', '#4882c5'],
            ['moderate', '#f09a46'],
            ['heavy', '#e34341'],
            ['severe', '#8b2342'],
          ],
        },
        'line-width': 7,
      },
      filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'selected'], ['!in', 'profile', 'foot']],
    },
    {
      id: 'directions-route-line-foot',
      type: 'line',
      source: 'directions',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          property: 'congestion',
          type: 'categorical',
          default: '#ff7201',
          stops: [
            ['unknown', '#4882c5'],
            ['low', '#4882c5'],
            ['moderate', '#f09a46'],
            ['heavy', '#e34341'],
            ['severe', '#8b2342'],
          ],
        },
        'line-dasharray': [0.1, 1.8],
        'line-width': 4.5,
      },
      filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'selected'], ['in', 'profile', 'foot']],
    },
    {
      id: 'directions-hover-point-casing',
      type: 'circle',
      source: 'directions',
      paint: {
        'circle-radius': 8,
        'circle-color': '#ff7201',
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'hover']],
    },
    {
      id: 'directions-hover-point',
      type: 'circle',
      source: 'directions',
      paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'hover']],
    },
    {
      id: 'directions-waypoint-point-casing',
      type: 'circle',
      source: 'directions',
      paint: {
        'circle-radius': 7,
        'circle-color': '#fff',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'waypoint']],
    },
    {
      id: 'directions-waypoint-point',
      type: 'circle',
      source: 'directions',
      paint: {
        'circle-radius': 6,
        'circle-color': '#8a8bc9',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'waypoint']],
    },
  ],
  points: [
    {
      id: 'directions-origin-point',
      type: 'symbol',
      source: 'directions',
      layout: {
        'text-field': 'A',
        'text-font': ['Open Sans Bold'],
        'text-size': 12,
        'icon-anchor': 'bottom-left',
        'text-anchor': 'top-right',
        'icon-offset': [-7, 8],
        'text-offset': [1.7, -1.9],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-image': 'pointRoutings',
        'icon-size': 1,
      },
      paint: {
        'text-color': '#000',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'A']],
    },
    {
      id: 'directions-origin-label',
      type: 'symbol',
      source: 'directions',
      layout: {
        'text-field': 'A',
        'text-font': ['Open Sans Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': 'rgba(0,0,0,0)',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'A']],
    },
    {
      id: 'directions-destination-label',
      type: 'symbol',
      source: 'directions',
      layout: {
        'text-field': '',
        'text-font': ['Open Sans Bold'],
        'text-size': 22,
      },
      paint: {
        'text-color': 'rgba(0,0,0,0)',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'B']],
    },
    {
      id: 'directions-destination-point',
      type: 'symbol',
      source: 'directions',
      layout: {
        'icon-anchor': 'bottom-left',
        'icon-offset': [-7, 8],
        'icon-allow-overlap': true,
        'icon-image': 'pointRoutings',
        'icon-size': 1,
        'text-field': 'B',
        'text-font': ['Open Sans Bold'],
        'text-size': 12,
        'text-anchor': 'top-right',
        'text-offset': [1.7, -1.9],
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': '#000',
        'icon-halo-width': 5,
        'icon-halo-blur': 5,
        'icon-halo-color': 'rgba(0,0,0,0.5)',
      },
      filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'B']],
    },
  ]
};

export default styles;
