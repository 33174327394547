/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import fetch from 'isomorphic-fetch';
import { hostResolver } from '@/src/helpers';
import * as CONST from '@/constants';
import * as types from '../types';

const REQUEST_OPTIONS = {
  headers: {
    Authorization: CONST.AUTHORIZATION,
  },
};

export const getStreet = (streetId, streetNumber) => async (dispatch) => {
  const req = await fetch(
    `${hostResolver(CONST.GET_STREET_URL)}?id=${streetId}${streetNumber ? `&number=${streetNumber}` : ''}`,
    REQUEST_OPTIONS
  ).catch(e => console.log(e));
  if (!req.ok) {
    return dispatch({
      type: types.GET_STREET,
      payload: null,
    });
  }
  const resp = await req.json();
  return dispatch({
    type: types.GET_STREET,
    payload: resp,
  });
};

export const fetchStreet = async (streetId, streetNumber) => {
  const req = await fetch(
    `${hostResolver(CONST.GET_STREET_URL)}?id=${streetId}${streetNumber ? `&number=${streetNumber}` : ''}`,
    REQUEST_OPTIONS
  ).catch(e => console.log(e));
  const resp = await req.json();
  return resp;
};

export const getCity = cityId => async (dispatch) => {
  const req = await fetch(`${hostResolver(CONST.GET_CITY_BY_ID)}?id=${cityId}`, REQUEST_OPTIONS);
  const resp = await req.json();
  return dispatch({
    type: types.GET_CITY,
    payload: resp,
  });
};

export const getRoutes = () => async (dispatch) => {
  const req = await fetch(`${hostResolver(CONST.GET_ROUTES_URL)}`, REQUEST_OPTIONS);
  const resp = await req.json();
  return dispatch({
    type: types.GET_ROUTES,
    payload: resp,
  });
};

export const getRoute = routeId => async (dispatch) => {
  const req = await fetch(`${hostResolver(CONST.GET_ROUTE_URL)}?rid=${routeId}`, REQUEST_OPTIONS);
  const resp = await req.json();
  return dispatch({
    type: types.GET_ROUTE,
    payload: resp,
  });
};

export const getObject = ({ companyId }) => async (dispatch) => {
  const req = await fetch(`${hostResolver(CONST.GET_OBJECT_URL)}?id=${companyId}`, REQUEST_OPTIONS);
  const resp = await req.json();
  return dispatch({
    type: types.GET_OBJECT,
    payload: resp,
  });
};

export const clearObject = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_OBJECT,
  });
};

export const getBranch = ({ companyId, branchId }) => async (dispatch) => {
  const req = await fetch(
    `${hostResolver(CONST.GET_BRANCH_URL)}?company_id=${companyId}&branch_id=${branchId}`,
    REQUEST_OPTIONS
  );
  const resp = await req.json();
  return dispatch({
    type: types.GET_BRANCH,
    payload: resp,
  });
};

export const getBuilding = (streetId, number) => async (dispatch) => {
  const req = await fetch(
    `${hostResolver(CONST.GET_STREET_URL)}?id=${streetId}&number=${number}`,
    REQUEST_OPTIONS
  );
  const resp = await req.json();
  return dispatch({
    type: types.GET_OBJECT,
    payload: resp,
  });
};

export const getCategories = parentId => async (dispatch) => {
  const url = `${hostResolver(CONST.GET_CATEGORIES_URL)}`;
  let requestUrl = url;
  if (parentId) {
    requestUrl = `${url}?parent_id=${parentId}`;
  }
  const req = await fetch(requestUrl, REQUEST_OPTIONS);
  const resp = await req.json();
  if (parentId) {
    return dispatch({
      type: types.GET_SUBCATEGORIES,
      payload: resp,
    });
  }
  return dispatch({
    type: types.GET_CATEGORIES,
    payload: resp,
  });
};

export const getCategory = categoryId => async (dispatch) => {
  const req = await fetch(`${hostResolver(CONST.GET_CATEGORY_URL)}?id=${categoryId}`, REQUEST_OPTIONS);
  const resp = await req.json();
  return dispatch({
    type: types.GET_CATEGORY,
    payload: resp,
  });
};

export const actionGetStreedByOSMID = async (id) => {
  const req = await fetch(`${hostResolver(CONST.GET_STREET_FROM_OSM_URL)}?id=${id}`, {
    ...REQUEST_OPTIONS,
  });
  const resp = await req.json();
  return resp;
};

export const actionGetBuildingByOSMID = async (id) => {
  const req = await fetch(`${hostResolver(CONST.GET_BUILDING_FROM_OSM_URL)}?id=${id}`, {
    ...REQUEST_OPTIONS,
  });
  const resp = await req.json();
  return resp;
};

export const actionGetBranchByOSMID = async (id) => {
  const req = await fetch(`${hostResolver(CONST.GET_BRANCH_FROM_OSM_URL)}?osm_id=${id}`, {
    ...REQUEST_OPTIONS,
  });
  if (!req.ok) {
    return null;
  }
  const resp = await req.json();
  return resp;
};

export const actionSaveUserMarker = async (form) => {
  const req = await fetch(`${hostResolver(CONST.USER_MARKERS_URL)}`, {
    ...REQUEST_OPTIONS,
    method: 'POST',
    body: form,
  });
  const resp = await req.json();
  return resp;
};

export const actionDeleteUserMarker = async (form) => {
  const req = await fetch(`${hostResolver(CONST.USER_MARKERS_URL)}`, {
    ...REQUEST_OPTIONS,
    method: 'DELETE',
    body: form,
  });
  const resp = await req.json();
  return resp;
};

export const getAllCompanyBranches = ({ categoryId, companyId, categoryUrl }) => async (dispatch) => {
  const url = `${hostResolver(CONST.GET_ALL_CATEGORY_BRANCHES_URL)}`;
  let requestUrl = `${url}?category_id=${categoryId}`;
  if (companyId) {
    requestUrl = `${url}?category_id=${categoryId}&company_id=${companyId}`;
  }
  if (categoryUrl) {
    requestUrl = `${url}?category_url=${categoryUrl}`;
  }
  const branchesReq = await fetch(
    requestUrl,
    REQUEST_OPTIONS
  );
  const branchesResp = await branchesReq.json();
  return branchesResp;
};

export const getCategoryItems = categoryUrl => async (dispatch) => {
  const categoryItemsReq = await fetch(
    `${hostResolver(CONST.GET_CATEGORY_ITEMS_URL)}?category_url=${categoryUrl}&take=90`,
    REQUEST_OPTIONS
  );

  if (!categoryItemsReq.ok) return ({ status: 404 });

  const categoryResp = await categoryItemsReq.json();
  
  const branchesReq = await fetch(
    `${hostResolver(CONST.GET_ALL_CATEGORY_BRANCHES_URL)}?category_id=${categoryResp.category._id}`,
    REQUEST_OPTIONS
  );
  const branchesResp = await branchesReq.json();

  return dispatch({
    type: types.GET_CATEGORY_ITEMS,
    payload: {
      items: categoryResp,
      allBranches: branchesResp,
    },
  });
};

export const getCompanyBranches = (companyId, categoryUrl = null, skip = 0, take = 30, onScroll = false) => async (dispatch) => {
  const req = await fetch(
    `${hostResolver(CONST.GET_COMPANY_BRANCHES_URL)}?id=${companyId}${categoryUrl ? `&category_url=${categoryUrl}` : ''}&skip=${skip}&take=${take}`,
    REQUEST_OPTIONS
  );
  const resp = await req.json();
  return dispatch({
    type: types.GET_COMPANY_BRANCHES,
    payload: { ...resp, onScroll },
  });
};

export const get3DObjects = (year = '1940', visible = 'true') => async (dispatch) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  let url = `${hostResolver(CONST.GET_3D_OBJECTS)}?v=${date.getTime()}&year=1940`; // panico and voloshin says to show objects from 1940 on all layers except 2020
  // const currentYear = date.getFullYear();
  // const intYear = parseInt(year, 10);
  // if (intYear !== 2020 && parseInt(year, 10) < 2020) {
  //   url += `&year=1940`;
  // }
  
  if (visible) {
    url += `&visible=${visible}`;
  }
  const req = await fetch(url, REQUEST_OPTIONS);
  if (!req.ok) {
    console.log('Error on get 3d objects!', req);
    return;
  }
  const resp = await req.json();
  // console.log(`3d objects response for year ${year}:`, resp);
  return dispatch({
    type: types.FETCH_3D_OBJECTS,
    payload: resp,
  });
};

export const getStyles = (year = '1940') => async (dispatch) => {
  let stylesGetUrl = CONST.GET_STYLES_URL_MAP;
  let satelliteStylesGetUrl = CONST.GET_STYLES_URL_SATELITE;
  // const date = new Date();
  // const currentYear = date.getFullYear();
  if (year && year !== '2020') {
    stylesGetUrl = `/tiles/${year}/styles/map/style.json`;
  }
  if (year && year !== '2020') {
    satelliteStylesGetUrl = `/tiles/${year}/styles/satelite/style.json`;
  }
  const [map, satelite] = await Promise.all([
    fetch(hostResolver(stylesGetUrl), REQUEST_OPTIONS)
      .then((resp) => {
        if (resp.ok) { return resp; }
        console.log('Error get Map style!', resp.statusText);
      })
      .then(json => json)
      .catch(e => console.log(e)),
    fetch(hostResolver(satelliteStylesGetUrl), REQUEST_OPTIONS)
      .then((resp) => {
        if (resp.ok) { return resp; }
        console.log('Error get Satellite style!', resp.statusText);
      })
      .then(json => json)
      .catch(e => console.log(e)),
  ]);
  if (!map || !satelite) return;
  const mapStyles = await map.json();
  const sateliteStyles = await satelite.json();
  return dispatch({
    type: types.GET_STYLE,
    payload: { mapStyles, sateliteStyles },
  });
};

export const setMapViewType = viewType => dispatch => dispatch({
  type: types.SET_MAP_VIEW_TYPE,
  payload: viewType,
});

export const toggleShareModal = IsOpened => dispatch => dispatch({
  type: types.TOGGLE_SHARE_MODAL,
  payload: IsOpened,
});

export const setMapMode = mode => dispatch => dispatch({
  type: types.SET_MAP_MODE,
  payload: mode,
});

export const initOnMount = canInit => dispatch => dispatch({
  type: types.SET_MAP_TOOLS_INITED,
  payload: canInit,
});

export const setOriginAddress = address => dispatch => dispatch({
  type: types.SET_ORIGIN_ADDRESS,
  payload: address,
});

export const setDestinationAddress = address => dispatch => dispatch({
  type: types.SET_DESTINATION_ADDRESS,
  payload: address,
});

export const setPublicRoutes = routes => dispatch => dispatch({
  type: types.SET_PUBLIC_ROUTES,
  payload: routes,
});

export const setDrivingRoutes = routes => dispatch => dispatch({
  type: types.SET_DRIVING_ROUTES,
  payload: routes,
});

export const setWalkingRoutes = routes => dispatch => dispatch({
  type: types.SET_WALKING_ROUTES,
  payload: routes,
});

export const clearRoutesStore = waypoint => dispatch => dispatch({
  type: types.CLEAR_ROUTES_STORE,
  payload: waypoint
});

export const setUser = ({ user, userAuth }) => dispatch => dispatch({
  type: types.SET_USER,
  payload: { user, userAuth } || {},
});

export const getSearchResults = query => (dispatch) => {
  let controller = {
    abort: () => {}
  };
  if (typeof AbortController !== 'undefined') {
    controller = new AbortController();
  }
  const { signal = '' } = controller;
  const promise = fetch(
    `${hostResolver(CONST.SEARCH_URL)}?q=${encodeURIComponent(query)}`,
    {
      ...REQUEST_OPTIONS,
      signal
    }
  )
    .then((res) => {
      // controller.abort();
      if (!res.ok) return;
      return res.json();
    })
    .then((json) => {
      let resp = json;
      if (!json.list) {
        resp = {
          list: []
        };
      }
  
      dispatch({
        type: types.GET_SEARCH_RESULTS,
        payload: resp,
      });
    })
    // eslint-disable-next-line handle-callback-err
    .catch((err) => {});
  return {
    promise,
    abortController: controller.abort.bind(controller),
    query
  };
  // if (!results.list.length) return;
};

export const clearCategoryItems = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_CATEGORY_RESULTS,
  });
};

export const clearCompanyBranches = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_COMPANY_BRANCHES,
  });
};

export const clearSearchResults = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_SEARCH_RESULTS,
  });
};

export const toggleSearchShow = show => (dispatch) => {
  dispatch({
    type: types.TOGGLE_SEARCH_SHOW,
    payload: show
  });
};

export const toggleInheritedSearchShow = show => (dispatch) => {
  dispatch({
    type: types.TOGGLE_INHERITED_SEARCH_SHOW,
    payload: show
  });
};

export const toggleMapShow = show => (dispatch) => {
  dispatch({
    type: types.TOGGLE_MAP_SHOW,
    payload: show,
  });
};

export const toggleLevelBtns = show => (dispatch) => {
  dispatch({
    type: types.TOGGLE_BUILDING_LEVEL_BUTTONS,
    payload: show,
  });
};

export const changeView = view => (dispatch) => {
  dispatch({
    type: types.CHANGE_VIEW,
    payload: view,
  });
};
