export const GET_STYLE = 'GET_STYLE';
export const SET_MAP_MODE = 'SET_MAP_MODE';
export const FETCH_3D_OBJECTS = 'FETCH_3D_OBJECTS';
export const SET_MAP_TOOLS_INITED = 'SET_MAP_TOOLS_INITED';
export const TOGGLE_MAP_SHOW = 'TOGGLE_MAP_SHOW';
export const TOGGLE_SEARCH_SHOW = 'TOGGLE_SEARCH_SHOW';
export const TOGGLE_INHERITED_SEARCH_SHOW = 'TOGGLE_INHERITED_SEARCH_SHOW';
export const TOGGLE_BUILDING_LEVEL_BUTTONS = 'TOGGLE_BUILDING_LEVEL_BUTTONS';
export const SET_USER = 'SET_USER';
export const GET_STREET = 'GET_STREET';
export const GET_CITY = 'GET_CITY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const GET_ROUTES = 'GET_ROUTES';
export const GET_ROUTE = 'GET_ROUTE';
export const GET_OBJECT = 'GET_OBJECT';
export const CLEAR_OBJECT = 'CLEAR_OBJECT';
export const CLEAR_BRANCH = 'CLEAR_BRANCH';
export const GET_BRANCH = 'GET_BRANCH';
export const GET_BUILDING = 'GET_BUILDING';
export const GET_CATEGORY_ITEMS = 'GET_CATEGORY_ITEMS';
export const GET_COMPANY_BRANCHES = 'GET_COMPANY_BRANCHES';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_PUBLIC_ROUTES = 'SET_PUBLIC_ROUTES';
export const SET_ORIGIN_ADDRESS = 'SET_ORIGIN_ADDRESS';
export const SET_DESTINATION_ADDRESS = 'SET_DESTINATION_ADDRESS';
export const SET_DRIVING_ROUTES = 'SET_DRIVING_ROUTES';
export const SET_WALKING_ROUTES = 'SET_WALKING_ROUTES';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const CLEAR_ROUTES_STORE = 'CLEAR_ROUTES_STORE';
export const CLEAR_CATEGORY_RESULTS = 'CLEAR_CATEGORY_RESULTS';
export const CLEAR_COMPANY_BRANCHES = 'CLEAR_COMPANY_BRANCHES';
export const SET_MAP_VIEW_TYPE = 'SET_MAP_VIEW_TYPE';
